// This file was autogenerated. Please do not change.
export interface IBankRequisitesRequest {
    assetId?: number;
    assetSymbol?: string;
    providerId?: number;
    providerName?: string;
}

export default class BankRequisitesRequest {
    readonly _assetId: number | undefined;

    /** Description: Asset platform ID */
    get assetId(): number | undefined {
        return this._assetId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Description: Asset platform symbol, e.g. EUR, USD, etc.
     * Example: EUR
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _providerId: number | undefined;

    /** Description: Blockchain platform ID */
    get providerId(): number | undefined {
        return this._providerId;
    }

    readonly _providerName: string | undefined;

    /**
     * Description: Banking provider name (SEPA, SWIFT)
     * Example: SEPA
     */
    get providerName(): string | undefined {
        return this._providerName;
    }

    constructor(props: IBankRequisitesRequest) {
        if (typeof props.assetId === 'number') {
            this._assetId = props.assetId;
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.providerId === 'number') {
            this._providerId = props.providerId;
        }
        if (typeof props.providerName === 'string') {
            this._providerName = props.providerName.trim();
        }
    }

    serialize(): IBankRequisitesRequest {
        const data: IBankRequisitesRequest = {
        };
        if (typeof this._assetId !== 'undefined') {
            data.assetId = this._assetId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._providerId !== 'undefined') {
            data.providerId = this._providerId;
        }
        if (typeof this._providerName !== 'undefined') {
            data.providerName = this._providerName;
        }
        return data;
    }

    toJSON(): IBankRequisitesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetId: !this._assetId ? true : typeof this._assetId === 'number',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            providerId: !this._providerId ? true : typeof this._providerId === 'number',
            providerName: !this._providerName ? true : typeof this._providerName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
