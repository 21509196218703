// This file was autogenerated. Please do not change.
export interface ICancelDepositRequestData {
    transferId?: string;
}

export default class CancelDepositRequestData {
    readonly _transferId: string | undefined;

    /** Description: Deposit UID */
    get transferId(): string | undefined {
        return this._transferId;
    }

    constructor(props: ICancelDepositRequestData) {
        if (typeof props.transferId === 'string') {
            this._transferId = props.transferId.trim();
        }
    }

    serialize(): ICancelDepositRequestData {
        const data: ICancelDepositRequestData = {
        };
        if (typeof this._transferId !== 'undefined') {
            data.transferId = this._transferId;
        }
        return data;
    }

    toJSON(): ICancelDepositRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            transferId: !this._transferId ? true : typeof this._transferId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
