
import Vue from 'vue';

import WalletsApi from 'Apis/Wallets';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import Transfer, { ITransfer } from 'Entities/privatePresenter/Transfer';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import PublicDataApi from 'Apis/PublicData';
import BankRequisitesRequest from 'Entities/publicPresenter/BankRequisitesRequest';
import TextInput from 'Control/TextInput.vue';

export default Vue.extend<any, any, any>({
    components: {
        TextInput,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            time: null,
            previousRoute: '/wallets',
            copyTextWithPermissions,
            historyData: {
                assetSymbol: '',
                blockchainName: '',
                createdAt: '',
                id: '',
                quantity: 0,
                bankRequisiteAlias: '',
                fee: {
                    amount: 0,
                    assetSymbol: '',
                },
            },
            requisites: null,
        };
    },
    async mounted() {
        await this.setModalData(new Transfer(this.$route.query as ITransfer));
        const { previousRoute } = this.$route.query;
        this.previousRoute = previousRoute;

        try {
            const { data: requisites } = await PublicDataApi.publicGetBankRequisites(new BankRequisitesRequest({
                assetSymbol: this.historyData.assetSymbol,
                providerName: this.historyData.blockchainName,
            }));
            this.requisites = requisites;
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting bank requisites');
            }
        }
    },
    computed: {
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
    methods: {
        copy(value: string, copiedValueName: string) {
            copyTextWithPermissions(value, this.$store.dispatch, copiedValueName);
        },
        showNotification() {
            this.$store.dispatch('Notificator/showSuccessNotification', 'Value Has Successfully Copied To Your Clipboard');
        },
        setTimer(time) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            if (time) {
                if (time < 900000) {
                    this.time = 900000 - time;
                } else {
                    this.time = 0;
                }
            } else {
                this.time = 900000;
            }
            this.intervalId = setInterval(() => {
                if (this.time === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                } else {
                    this.time -= 1000;
                }
            }, 1000);
        },
        validateTimeNumber(num) {
            if (Number(num) < 10) {
                return `0${num}`;
            }
            return String(num);
        },
        async setModalData(e) {
            try {
                this.historyData.assetSymbol = e.assetSymbol;
                this.historyData.blockchainName = e.blockchainName;
                this.historyData.createdAt = e.createdAt;
                this.historyData.id = e.id;
                this.historyData.quantity = e.quantity;
                this.historyData.bankRequisiteAlias = e.bankRequisiteAlias;
                if (e.commission && e.commission?.length > 0) {
                    this.historyData.fee.amount = e.commission[0].value;
                    this.historyData.fee.assetSymbol = e.commission[0].assetSymbol;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during setting modal data');
                }
            }
        },
        async cancelTransfer(id) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
    },
});
